<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div v-if="isLoading" class="spinner-border text-primary mt-4 m-auto">Завантаження...</div>

            <template v-else>
                <div class="col-12 layout-spacing">
                    <div class="panel-body">
                        <div v-if="isLoading" class="d-flex justify-content-center mb-4">
                            <div class="spinner-border text-primary align-self-center">Завантаження...</div>
                        </div>
                        <div v-else class="table-responsive">
                            <div class="row my-2">
                                <div class="col">
                                    <h6>Інтервал дат:</h6>
                                </div>
                            </div>
                            <div class="date-time-picker-container row my-2">
                      <!-- Start Date and Time -->
                      <div class="picker-group col-12 mb-3 d-flex align-items-end">
                        <div class="picker flex-grow-1 me-2">
                          <label>Дата початку:</label>
                            <Datepicker v-model="startDate" :format="'YYYY-MM-DD'" ref="startDate" class="form-control" />
                        </div>
                        <div class="picker flex-grow-1">
                          <label>Час початку:</label>
                          <flat-pickr
                            v-model="startTime"
                            :config="timeConfig"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <!-- End Date and Time -->
                      <div class="picker-group col-12 mb-3 d-flex align-items-end">
                        <div class="picker flex-grow-1 me-2">
                          <label>Дата завершення:</label>
                            <Datepicker v-model="endDate" :format="'YYYY-MM-DD'" ref="endDate" class="form-control" />
                        </div>
                        <div class="picker flex-grow-1">
                          <label>Час завершення:</label>
                          <flat-pickr
                            v-model="endTime"
                            :config="timeConfig"
                            class="form-control"
                          />
                        </div>
                      </div>

                      <!-- Apply Button -->
                      <div class="col-12 mb-1 text-end">
                        <button @click="loadInfo" :disabled="isLoading" class="btn btn-primary">Застосувати</button>
                      </div>
                    </div>
                            <table role="table" aria-busy="false" aria-colcount="5" class="table table-hover b-table table-bordered" id="__BVID__328">
                                <thead role="rowgroup">
                                    <tr role="row">
                                        <th role="columnheader" scope="col"><div>#</div></th>
                                        <th role="columnheader" scope="col"><div>Імя</div></th>
                                        <th role="columnheader" scope="col"><div>Готівка</div></th>
                                        <th role="columnheader" scope="col"><div>QR</div></th>
                                        <th role="columnheader" scope="col"><div>POS</div></th>
                                        <th role="columnheader" scope="col" class="text-center"><div>Всього</div></th>
                                        <th role="columnheader" scope="col"><div>Інкасація(час)</div></th>
                                        <th role="columnheader" scope="col"><div>Інкасація(сума)</div></th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">
                                    <template v-for="(group, groupIndex) in data" :key="group.groupId">
                                        <tr role="row" class="table-success">
                                            <td role="cell" colspan="8"><strong>{{ group.groupId }}</strong></td>
                                        </tr>

                                        <tr v-for="(device, deviceIndex) in group.devices" :key="device.deviceId" role="row" class="cursor-pointer">
                                            <td role="cell">{{ deviceIndex + 1 }}</td>
                                            <td role="cell">{{ device.deviceName }}</td>
                                            <td role="cell">{{ $prettyAmount(device.stats.cashAmount) }}</td>
                                            <td role="cell">{{ $prettyAmount(device.stats.qrAmount) }}</td>
                                            <td role="cell">{{ $prettyAmount(device.stats.cardReplenishmentsPosAmount) }}</td>
                                            <td role="cell">
                                                {{ $prettyAmount(device.stats.cashAmount+device.stats.qrAmount+device.stats.cardReplenishmentsPosAmount) }}
                                            </td>
                                            <td role="cell">{{ $getFormattedDateTime(device.lastCashCollectionTime) }}</td>
                                            <td role="cell">{{ $prettyAmount(device.lastCashCollectionAmount) }}</td>
                                        </tr>
                                        <tr role="row" class="table-danger">
                                            <td colspan="2" class="text-right">Сума по групі:</td>
                                            <td>{{ $prettyAmount(getGroupTotal(group, 'cashAmount')) }}</td>
                                            <td>{{ $prettyAmount(getGroupTotal(group, 'qrAmount')) }}</td>
                                            <td>{{ $prettyAmount(getGroupTotal(group, 'cardReplenishmentsPosAmount')) }}</td>
                                            <td>
                                                {{ $prettyAmount(getGroupTotal(group, 'cashAmount')+getGroupTotal(group, 'qrAmount')+getGroupTotal(group, 'cardReplenishmentsPosAmount')) }}
                                            </td>
                                            <td></td>
                                            <td>{{ $prettyAmount(getCashCollectionTotal(group)) }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import { useMeta } from '@/composables/use-meta';
    import dayjs from 'dayjs';
    import '@/assets/sass/widgets/widgets.scss';

    import Datepicker from 'vue3-datepicker';

    import flatPickr from 'vue-flatpickr-component';
    import 'flatpickr/dist/flatpickr.css';
    import '@/assets/sass/forms/custom-flatpickr.css';
    import { Ukrainian } from "flatpickr/dist/l10n/uk.js"
    flatpickr.localize(Ukrainian);

    useMeta({ title: 'Dashboard' });
</script>

<script>
    export default {
        name: 'Dashboard',

        components: {},

        data: () => ({
            isLoading: false,

            data: null,
            chartDataCash: {},
            chartDataCard: {},
            DEVICE_TYPES: {},

            currentDayTimestamp: dayjs(dayjs().format('YYYY-MM-DD')).unix(),
            yesterdayDayTimestamp: dayjs(dayjs().add(-1, 'days').format('YYYY-MM-DD')).unix(),
            last7daysData: {
                cashAmount: 0,
                qrAmount: 0,
                cardReplenishmentsPosAmount: 0
            },

            categoriesCash: [],
            categoriesCard: [],
            chartCashIsActive: false,
            chartCardIsActive: false,

            startDate: new Date(),
            startTime: '00:01',
            endDate: new Date(),
            endTime: '23:59',

            timeConfig: {
                locale: Ukrainian,
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',  // 24-hour format for time (e.g., 14:30)
                time_24hr: true,  // Ensures 24-hour format
            }
        }),

        async mounted() {
            await this.loadInfo();
        },

        methods: {
            async loadInfo() {
                this.isLoading = true;

                const formattedStartDateTime = this.formatDateTime(new Date(this.startDate), this.startTime);
                const formattedEndDateTime = this.formatDateTime(new Date(this.endDate), this.endTime);

                const startDateFormatted = dayjs(formattedStartDateTime).format('YYYY-MM-DD HH:mm:ss');
                const endDateFormatted = dayjs(formattedEndDateTime).format('YYYY-MM-DD HH:mm:ss');


                let url = `dashboard?startDate=${startDateFormatted}&endDate=${endDateFormatted}`;

                const response = await this.$json.get(url);

                if (!response || response?.statusCode == 401) {
                    console.log('UNAUTHORIZED');
                    await getWhoAmI();
                    this.$router.push('/');
                    return;
                }

                this.data = response.json.groups;

                this.isLoading = false;
            },

            formatDateTime(date, time) {
              const [hours, minutes] = time.split(':');
              date.setHours(hours, minutes, 0, 0);
              return date;
            },

            getTotal(field) {
                if (!this.data) return 0;
                return this.data.reduce((total, group) => {
                    return total + this.getGroupTotal(group, field);
                }, 0);
            },

            getGroupTotal(group, field) {
                if (!group || !group.devices) return 0;
                return group.devices.reduce((total, device) => {
                    return total + (device.stats[field] || 0);
                }, 0);
            },

            getCashCollectionTotal(group) {
                if (!group || !group.devices) return 0;

                return group.devices.reduce((total, device) => {
                    return total + (device.lastCashCollectionAmount || 0);
                }, 0);
            },
        },
    };
</script>
